<template>
  <div class="container">
    <div class="top_title">首页</div>

    <div class="content">
      <div class="echarts_box">
        <div class="echarts">
          <div class="card-header">一周订单统计</div>
          <div
            id="echarts01"
            style="width: 100%; height: 300px; margin-top: 10px"
          ></div>
        </div>
        <div class="echarts card">
          <div class="card-header">每日数据统计</div>
          <div class="card-body">
            <div>
              <div class="card-header">每日看板</div>
              <div class="card-body items">
                <div class="item">
                  <div class="number">{{ weekInfo.visitTotal || 0 }}</div>
                  <div>累计用户数</div>
                </div>
                <div class="item">
                  <div class="number">{{ weekInfo.sharePv || 0 }}</div>
                  <div>转发次数</div>
                </div>

                <div class="item">
                  <div class="number">{{ weekInfo.shareUv || 0 }}</div>
                  <div>转发人数</div>
                </div>
                <div class="item">
                  <div class="number">{{ weekInfo.sessionCnt || 0 }}</div>
                  <div>打开次数</div>
                </div>
                <div class="item">
                  <div class="number">{{ weekInfo.visitPv || 0 }}</div>
                  <div>访问次数</div>
                </div>
                <div class="item">
                  <div class="number">{{ weekInfo.visitUv || 0 }}</div>
                  <div>访问人数</div>
                </div>
                <div class="item">
                  <div class="number">{{ weekInfo.visitUvNew || 0 }}</div>
                  <div>新用户数</div>
                </div>
              </div>
              <div class="card-header" style="top: 40px">订单看板</div>
              <div class="card-body items" style="top: 45px">
                <div class="item">
                  <div class="number">{{ weekInfo.todayCount || 0 }}</div>
                  <div>今日订单数</div>
                </div>
                <div class="item">
                  <div class="number">{{ weekInfo.redeemOrderCount || 0 }}</div>
                  <div>今日退款订单</div>
                </div>
                <div class="item">
                  <div class="number">{{ weekInfo.todayTurnover || 0 }}</div>
                  <div>今日订单收益</div>
                </div>
                <div
                  class="item"
                  onclick="location.href='#/order/orderList?status=1'"
                >
                  <div class="number">{{ weekInfo.isPayOrderCount || 0 }}</div>
                  <div>今日已支付订单</div>
                </div>
                <div
                  class="item"
                  onclick="location.href='#/order/orderList?status=1'"
                >
                  <div class="number">{{ weekInfo.noOkOrderCount || 0 }}</div>
                  <div>今日待支付订单</div>
                </div>
                <!-- <div class="item">
                  <div class="number">{{ weekInfo.noOkOrderCount || 0 }}</div>
                  <div>今日待确认订单</div>
                </div> -->
                <div
                  class="item"
                  onclick="location.href='#/order/orderList?status=2'"
                >
                  <div class="number">{{ weekInfo.waitOrderCount || 0 }}</div>
                  <div>待入住/游玩订单</div>
                </div>
                <div class="item" onclick="location.href='#/order/orderList'">
                  <div class="number">{{ weekInfo.totalCount || 0 }}</div>
                  <div>总订单数量</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="echarts_box">
        <div class="echarts">
          <div class="card-header">一周会员统计</div>
          <div
            id="echarts02"
            style="width: 100%; height: 300px; margin-top: 10px"
          ></div>
        </div>
        <div class="echarts">
          <div class="card-header">万小店周申请统计</div>
          <div
            id="echarts03"
            style="width: 100%; height: 300px; margin-top: 10px"
          ></div>
        </div>
      </div>
      <div class="echarts_box">
        <div class="echarts">
          <div class="card-header">订单/会员月统计</div>
          <div
            id="echarts04"
            style="width: 100%; height: 300px; margin-top: 10px"
          ></div>
        </div>
        <div class="echarts">
          <div class="card-header">客房销售排行榜Top10</div>
          <div
            id="echarts1_1"
            style="width: 100%; height: 300px; margin-top: 10px"
          ></div>
        </div>
      </div>
      <div class="echarts_box">
        <div class="echarts">
          <div class="card-header">线路销售排行榜Top10</div>
          <div
            id="echarts1_3"
            style="width: 100%; height: 300px; margin-top: 10px"
          ></div>
        </div>
        <div class="echarts">
          <div class="card-header">门票销售排行榜Top10</div>
          <div
            id="echarts1_2"
            style="width: 100%; height: 300px; margin-top: 10px"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
// import echarts from 'echarts'
import {
  selectOneWeekInfo,
  selectUserAndOrderMonth,
  selectSpInfo,
  selectTodayOrder,
  memberWeek,
  wxdWeekApply,
  selectRoomSellTopTenByMonth,
} from "../../api/homs.js";
export default {
  name: "home",
  components: {},
  data: () => {
    return {
      weekInfo: {},
      orderTimes:[]
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.selectOneWeekInfo();
      this.selectSpInfo();
      this.selectTodayOrder();
      this.memberWeek();
      this.wxdWeekApply();
      this.selectUserAndOrderMonth();
      this.selectRoomSellTopTenByMonth(1);
      this.selectRoomSellTopTenByMonth(2);
      this.selectRoomSellTopTenByMonth(3);
    });
  },
  methods: {
    async selectOneWeekInfo() {
      const { data } = await selectOneWeekInfo();
      let myChart = echarts.init(
        document.getElementById("echarts01"),
        "macarons"
      );
      myChart.on("click",  (params)=> {
        console.log(params,'paramsparamsparamsparams');
        if(params.componentSubType!="bar") return
         let time = this.orderTimes[params.dataIndex]
         this.$router.push('/orderAll?time='+time)
      });
      this.orderTimes = data.data[0].allTimes
      let newData = data.data || [];
      newData.forEach((item) => {
        console.log("一周订单统计数据回调遍历", item);
        var option1 = {
          title: {
            text: "一周订单统计",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["订单数量"],
          },
          toolbox: {
            show: true,
            feature: {
              dataView: {
                show: true,
                readOnly: false,
              },
              magicType: {
                show: true,
                type: ["line", "bar"],
              },
              restore: {
                show: true,
              },
              saveAsImage: {
                show: true,
              },
            },
          },
          calculable: true,
          xAxis: [
            {
              type: "category",
              data: item.times.split(","),
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],

          series: [
            {
              name: "订单数量",
              type: "bar",
              data: item.timesNum.split(","),
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "最大值",
                  },
                  {
                    type: "min",
                    name: "最小值",
                  },
                ],
              },
              markLine: {
                data: [
                  {
                    type: "average",
                    name: "平均值",
                  },
                ],
              },
            },
          ],
        };
        myChart.setOption(option1);
      });
    },
    async wxdWeekApply() {
      const { data } = await wxdWeekApply();
      let myChart = echarts.init(
        document.getElementById("echarts03"),
        "macarons"
      );
      let newData = data.data || [];
      newData.forEach((item) => {
        console.log("万小店周申请统计数据回调遍历", item);
        var option = {
          title: {
            text: "万小店周申请统计",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["会员数量"],
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: item.times.split(","),
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: item.timesNum.split(","),
              type: "line",
              areaStyle: {},
            },
          ],
        };
        myChart.setOption(option);
      });
    },
    async memberWeek() {
      const { data } = await memberWeek();
      let myChart = echarts.init(
        document.getElementById("echarts02"),
        "macarons"
      );
      let newData = data.data || [];
      newData.forEach((item) => {
        console.log("一周会员统计数据回调遍历", item);
        var option = {
          title: {
            text: "一周会员统计",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["会员数量"],
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: item.times.split(","),
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: item.timesNum.split(","),
              type: "line",
              areaStyle: {},
            },
          ],
        };
        myChart.setOption(option);
      });
    },
    async selectUserAndOrderMonth() {
      const { data } = await selectUserAndOrderMonth();
      let myChart = echarts.init(
        document.getElementById("echarts04"),
        "macarons"
      );
      let newData = data.data || [];
      newData.forEach((item) => {
        console.log("订单/会员月统计数据回调遍历", item);
        var option = {
          title: {
            text: "订单/会员本月统计(" + item.timesNum + ")",
            subtext: "",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["订单统计", "会员统计"],
          },
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: "none",
              },
              dataView: {
                readOnly: false,
              },
              magicType: {
                type: ["line", "bar"],
              },
              restore: {},
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: item.times.split(","),
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "订单统计",
              type: "line",
              data: item.orderNum.split(","),
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "最大值",
                  },
                  {
                    type: "min",
                    name: "最小值",
                  },
                ],
              },
              markLine: {
                data: [
                  {
                    type: "average",
                    name: "平均值",
                  },
                ],
              },
            },
            {
              name: "会员统计",
              type: "line",
              data: item.userNum.split(","),
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "最大值",
                  },
                  {
                    type: "min",
                    name: "最小值",
                  },
                ],
              },
              markLine: {
                data: [
                  {
                    type: "average",
                    name: "平均值",
                  },
                  [
                    {
                      symbol: "none",
                      x: "90%",
                      yAxis: "max",
                    },
                    {
                      symbol: "circle",
                      label: {
                        normal: {
                          position: "start",
                          formatter: "最大值",
                        },
                      },
                      type: "max",
                      name: "最高点",
                    },
                  ],
                ],
              },
            },
          ],
        };
        myChart.setOption(option);
      });
    },
    async selectRoomSellTopTenByMonth(prodType) {
      const { data } = await selectRoomSellTopTenByMonth({ prodType });
      let myChart = echarts.init(
        document.getElementById("echarts1_" + prodType),
        "macarons"
      );
      let newData = data.data || [];
      let title = "";
      if (prodType == 1) title = "客房";
      if (prodType == 2) title = "门票";
      if (prodType == 3) title = "线路";
      newData.forEach((item) => {
        console.log("万小店周申请统计数据回调遍历", item);
        let yData = item.prodNames.split(",");
        let yData2 = yData.map((item) => {
          if (item && item.length > 10) {
            return item.slice(0, 10) + "...";
          }
          return item || "";
        });
        var option = {
          title: {
            text: title + "销售排行榜TOP10",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: item.nums,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: "category",
            data: yData,
            axisLabel: {
              formatter: function (value) {
                if (value && value.length > 10)
                  return value.slice(0, 10) + "...";
                return value || "";
              },
            },
          },
          series: [
            {
              name: item.times,
              type: "bar",
              data: item.nums,
            },
          ],
        };
        myChart.setOption(option);
      });
    },
    async selectSpInfo() {
      const { data } = await selectSpInfo();
      if (data.code == 0) {
        let newData = data.data[0] || {};
        for (var k in newData) {
          this.weekInfo[k] = newData[k];
          console.log(k, newData[k]);
        }
        this.weekInfo = { ...this.weekInfo };
      }
    },
    async selectTodayOrder() {
      const { data } = await selectTodayOrder();
      if (data.code == 0) {
        let newData = data.data[0] || {};
        for (var k in newData) {
          this.weekInfo[k] = newData[k];
          console.log(k, newData[k]);
        }
        this.weekInfo = { ...this.weekInfo };
        console.log(this.weekInfo);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .top_title {
    padding: 0 0 40px;
    font-size: 1.2em;
    font-weight: bold;
    border-bottom: 1px solid #e5e5e5;
    background-color: #ffffff;
  }

  .content {
    background-color: #f3f3f3;
    padding: 20px;
    margin: -20px;
    .echarts_box {
      display: flex;

      .echarts {
        flex: 1;
        min-width: 400px;
        min-height: 350px;
        position: relative;
        padding: 10px 15px;
        line-height: 24px;
        margin-bottom: 20px;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        .card-header {
          position: relative;
          height: 42px;
          line-height: 42px;
          padding: 0 15px;
          border-bottom: 1px solid #f6f6f6;
          color: #333;
          border-radius: 2px 2px 0 0;
          font-size: 14px;
        }

        .card-body {
          position: relative;
          padding: 10px 0px;
          line-height: 24px;
        }
        .items {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .item {
            flex: 1;
            font-size: 14px;
            color: #333333;
            text-align: center;
            .number {
              margin-bottom: 4px;
              color: #f05f5c;
              font-size: 28px;
              font-weight: 600;
              text-align: center;
              cursor: pointer;
            }
          }
        }

        &:nth-child(2n) {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
