import request from '../utils/request'
import baseUrl from './baseUrl'
export const selectOneWeekInfo = (data) => request({
  url: baseUrl + '/order/selectOneWeekInfo',
  method: 'POST',
  data
})
export const selectOneInfoWxd = (data) => request({
  url: baseUrl + '/order/selectTodayWxd',
  method: 'POST',
  data
})
export const selectOneInfoWxdOrder = (data) => request({
  url: baseUrl + '/order/selectTodayOrderWxd',
  method: 'POST',
  data
})
export const selectWxdAddList = (data) => request({
  url: baseUrl + '/order/selectOneWeekApply',
  method: 'POST',
  data
})
export const selectWxdShopWeek = (data) => request({
  url: baseUrl + '/order/selectOneWeekApply',
  method: 'POST',
  data
})
export const selectWxdShopWeekOrder = (data) => request({
  url: baseUrl + '/order/selectOneWeekOrderCounts',
  method: 'POST',
  data
})
export const selectWxdShopWeekOrderMonth = (data) => request({
  url: baseUrl + '/order/selectApplyAndOrderMonth',
  method: 'POST',
  data
})
export const selectSpInfo = (data) => request({
  url: baseUrl + '/order/selectSpInfo',
  method: 'POST',
  data
})
export const selectTodayOrder = (data) => request({
  url: baseUrl + '/order/selectTodayOrder',
  method: 'POST',
  data
})
export const memberWeek = (data) => request({
  url: baseUrl + '/userInfo/selectOneWeekInfo',
  method: 'POST',
  data
})
export const memberWeek_2 = (data) => request({
  url: baseUrl + '/order/posterWeekDownWxd',
  method: 'POST',
  data
})
export const wxdWeekApply = (data) => request({
  url: baseUrl + '/distributionApply/selectOneWeekInfo',
  method: 'POST',
  data
})

export const selectUserAndOrderMonth = (data) => request({
  url: baseUrl + '/order/selectUserAndOrderMonth',
  method: 'POST',
  data
})
export const selectRoomSellTopTenByMonth = (data) => request({
  url: baseUrl + '/order/selectRoomSellTopTenByMonth',
  method: 'POST',
  data
})
export const selectRoomSellTopTenByMonth_1 = (data) => request({
  url: baseUrl + '/order/roomTopWxd',
  method: 'POST',
  data
})
export const selectRoomSellTopTenByMonth_2 = (data) => request({
  url: baseUrl + '/order/travelTopWxd',
  method: 'POST',
  data
})
export const selectRoomSellTopTenByMonth_3 = (data) => request({
  url: baseUrl + '/order/ticketTopWxd',
  method: 'POST',
  data
})

